body {
    overflow-x: hidden;
}

.item-sale-cont {
    padding-left: 150px;
    margin-bottom: 150px;
}

.item-page {
    margin-top: 30px;
}

hr {
    /* Add styles for the dividing line */
    border: 1px solid #000000;
}

p {
    /* Add styles for the paragraph containing categories */
    font-size: 18px; /* Adjust the font size as needed */
}

/* Base styles for anchor (link) elements */
a {
    text-decoration: none; /* Remove underline */
    color: grey; /* Set text color to grey */
    margin-right: 10px; /* Add some spacing between categories */
    font-size: 16px; /* Adjust the font size as needed */
    transition: color 0.3s ease; /* Smooth transition for color changes */
}

/* Hover styles for anchor (link) elements */
a:hover {
    color: black; /* Change text color to black when hovered */
}

.buy-button {
    display: flex;
    align-items: center; /* Vertically align icon and text */
    border-radius: 0;
    border-color: #384259;
    padding: 15px 50px;
    font-size: 26px;
    background-color: #384259;
    color: white;
}

.buy-button-col {
    display: flex;
    justify-content: center;
}

.item-price {
    font-size: 2.5rem; /* Adjust the font size as needed */
    font-weight: bold; /* Make the price bold */
    margin-top: 75px; /* Optional: add some space above the price */
    margin-bottom: 50px; /* Optional: add space below the price */
}

.buy-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.desc-cont {
    margin-bottom: 50px;
}

.specs-cont {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 70px;
    background-color: #6b6b6b46;
}

.group-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add spacing between the buttons */
    margin-top: 10px;
}

.group-item-button {
    position: relative; 
    border: 2px solid transparent; 
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;
}

.group-item-button:hover {
    border-color: #ccc; 
}

.group-item-button.selected {
    border-color: #007bff;
}

.group-item-button.selected:hover {
    border-color: #007bff; 
}

.group-item-button.selected::after {
    content: "✓"; 
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    color: #007bff; 
    font-weight: bold;
}

.variations-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

.item-variations .group-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.variation-price {
    margin-left: 10px;
    font-weight: normal;
    font-size: 14px;
}

.variation-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px;
    transition: background-color 0.3s ease;
}

.variation-box.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.variation-box:hover {
    background-color: #f0f0f0;
}

.variation-box.selected:hover {
    background-color: #006bb3;
}

/* New styles for the item info container */
.item-buy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa; /* Light gray background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.item-purchase-section {
    margin-top: 30px; /* Adds spacing above */
}

.variations-container {
    padding-right: 20px; /* Adds spacing between variations & buying container */
}

.item-info-container h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.item-categories {
    font-size: 18px;
}

.item-price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.buy-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.product_code-hs-row {
    display: flex;
    gap: 20px; /* Space between product_code and HS Code */
    font-size: 14px; /* Small text */
    color: gray; /* Gray text */
    margin-top: 5px; /* Small spacing below the item name */
}

/* Stock Status Container */
.stock-status {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    padding: 22px 0;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 95%;
}


.price-thumbnail-row {
    margin-top: 30px; /* Spacing between stock status and price */
    margin-bottom: 70px;
    display: flex;
    justify-content: space-between; /* Ensures price is left & thumbnail is right */
    align-items: flex-start; /* Aligns items vertically at the top */
    width: 100%;
}

.price-container {
    display: flex;
    justify-content: flex-start; /* Aligns price to the left */
    align-items: flex-start; /* Aligns price at the top */
    width: 100%;
}

.thumbnail-container {
    display: flex;
    justify-content: flex-end; /* Aligns thumbnail to the right */
    align-items: flex-start; /* Aligns thumbnail at the top */
    width: 100%;
    margin: 0;
}

.thumbnail-placeholder {
    width: 100px; /* Adjust size as needed */
    height: 50px;
    background-color: #ffffff; /* Placeholder color */
}

.thumbnail-img {
    width: 100%;
    height: 100%;
    object-fit: contain ;
}

.price-container p {
    margin: 0; /* Remove default margin from the <p> element */
    padding: 0; /* Remove any padding */
    display: flex;
    align-items: flex-start; /* Ensures price is aligned at the top */
}

.buy-button .out-of-stock {
    background-color: #6c757d; /* Gray background */
    border-color: #6c757d;     /* Gray border */
    color: #ffffff;            /* White text */
    cursor: not-allowed;       /* Make it look disabled */
}

.item-buy-container .stock-status.in-stock {
    background-color: green;
    color: white;
  }
  
.item-buy-container .stock-status.available-after-ordering {
    background-color: green;
    color: white;
}

.item-buy-container .stock-status.available-3-to-5 {
    background-color: #ffcc00;
    color: white;
}

.item-buy-container .stock-status.available-7-to-10 {
    background-color: #ff9900;
    color: white;
}

.item-buy-container .stock-status.out-of-stock {
    background-color: red;
    color: white;
}

.item-buy-container .stock-status.unknown {
    background-color: gray;
    color: white;
}
