.shopping-cart-body {
    width: 1300px;
    margin: auto;
}

th[colspan="2"] {
    /* Add styles specific to the "Item(s)" header */
    font-size: 20px; /* Adjust font size as needed */
    /* You can add other styles here as well */
  }

.shopping-cart-title {
    margin-top: 50px;
    margin-bottom: 75px;
}

.cart-table-row {
    margin-top: 20px;
    margin-bottom: 80px;
}

.checkout-btn {
    margin-top: 20px;
    margin-bottom: 85px;
    border-radius: 0;
    background-color: #00446a;
}

.checkout-btn:hover {
    background-color: #00446a;
  }

.empty-cart-message {
    font-size: 1.2rem;
    color: #777;
    border: 1px dashed #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 20px;
}
