.wider-column {
  width: 50%; /* Adjust the width as needed */
}

.cart-item-thumbnail {
  height: 100px;
}

.table-quantity,
.table-price,
.table-unit-price, /* New Unit Price Column */
.table-total-price,
.table-actions {
  vertical-align: middle;
  text-align: center;
}

.cart-item-link {
  color: #00446a; /* Link color */
  font-weight: bold;
  text-decoration: none; /* Remove underline */
}

.cart-item-link:hover {
  text-decoration: underline;
}

/* Ensure the unit price column doesn't take too much space */
.table-unit-price {
  width: 10%;
}

/* Make sure the total price column is aligned properly */
.table-total-price {
  width: 15%;
  font-weight: bold;
}

/* Adjust the quantity column width */
.table-quantity {
  width: 12%;
}

.empty-cart-message {
  font-size: 1.2rem;
  color: #777;
  border: 1px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.selected-variations {
  margin-top: 5px;
  color: #666; /* Light gray color for small text */
  font-size: 0.9em; /* Smaller font size */
}