/* Checkout Item Table Specific Styles */
.checkout-wider-column {
  width: 50%; /* Adjust the width as needed */
}

.checkout-cart-item-thumbnail {
  height: 100px; /* Adjust the height as needed */
}

.checkout-table-quantity, .checkout-table-price, .checkout-table-actions {
  vertical-align: middle;
}

.checkout-item-table th, .checkout-item-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Add subtle bottom border */
}

.checkout-item-table {
  width: 100%; /* Make table take full available width */
  table-layout: auto; /* Let the table dynamically adjust based on content */
  border-collapse: collapse; /* Merge borders for cleaner design */
}

.checkout-table-actions {
  text-align: center;  /* Centers the content horizontally */
}

.checkout-table-actions .btn {
  margin-left: 15px;
  margin: 0 5px;  /* Adds space between the buttons */
  display: inline-block;  /* Makes sure buttons are aligned horizontally */
}

.checkout-cart-item-link {
  color: #00446a; /* Link color */
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  margin-left: 10px; /* Add spacing between image and text */
}

.checkout-cart-item-link:hover {
  text-decoration: underline;
}


/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .checkout-cart-item-thumbnail {
    height: 80px; /* Smaller thumbnail on mobile */
  }

  .checkout-item-table {
    font-size: 14px; /* Reduce font size for mobile */
  }

  .checkout-item-table th, .checkout-item-table td {
    padding: 10px; /* Adjust padding for mobile */
  }
}
