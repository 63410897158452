.checkout-cont {
    margin-top: 30px;
    margin-bottom: 50px;
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
    width: 100%; /* Full width for the container */
}

.checkout-left,
.checkout-right {
    background-color: #f9f9f9;
    padding: 0px;
    border-radius: 8px;
    margin: 0; /* Ensure no margin on the sides */
}

.checkout-left {
    width: 100%;
}

.checkout-right {
    background-color: #ffffff;
    padding: 0;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling if table overflows */
}

.checkout-left-cont {
    flex: 2;
}

.checkout-right-cont {
    flex: 1;
}

.checkout-title {
    margin-bottom: 40px;
}

.checkout-order-title {
    margin-top: 40px;
    margin-bottom: 35px;
}

.order-type-select {
    margin-bottom: 30px;
}

.checkout-cont .form-label {
    margin-top: 5px; /* Adjust the value as needed */
}


/* Order Summary Section */
.order-summary {
    position:  sticky;
    top: 200px;
    align-self: flex-start;
    z-index: 1000;
    background-color: #f0ecec;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
  
.order-summary-title {
    font-size: 1.35rem;
    font-weight: bold;
    margin-bottom: 25px;
}
  
.order-summary-details {
    width: 100%;
}
  
  .order-summary-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .order-summary-item.total {
    font-size: 1.2em;
  }
  
  
  .order-summary-divider {
    margin-top: 35px;
  }
  
  .order-now-button {
    width: 100%;
    margin-top: 20px;
  }
  

@media (max-width: 768px) {
    .checkout-left,
    .checkout-right {
        width: 100%;
        margin: 0 0 20px 0; /* Stack columns on smaller screens */
    }
    
    .checkout-cont {
        padding-left: 0;
        padding-right: 0;
    }

    .order-now-button {
        width: 100%; /* Button spans the entire width on small screens */
    }
}


/* Shipping option card styles */
.shipping-option-card {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .shipping-option-card.selected {
    border-color: lightblue; /* Light blue border when selected */
    background-color: #f0f8ff; /* Light blue background when selected */
  }
  
  .shipping-info {
    margin-left: 55px;
  }
  
  .shipping-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .shipping-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .shipping-option-card img {
    max-width: 70px;
    max-height: 70px;
  }
  
  /* Styling for the loading spinner */
.loading-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #f3f3f3; /* Light gray background */
  border-top: 3px solid #3498db; /* Blue color for spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin: 10px 0;
}

/* Animation for the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
