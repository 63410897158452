/* Carousel container */
.image-cont {
  max-width: 550px; /* Adjust the maximum width as needed */
  max-height: 550px;
  margin: auto; /* Center the Carousel */
  position: relative; /* Ensure thumbnails are positioned relative to the carousel */
}

/* Style for thumbnail container */
.item-thumbnail-container {
  display: flex;
  flex-wrap: wrap; /* Allow the thumbnails to wrap to the next line */
  justify-content: center; /* Center the thumbnails horizontally */
  gap: 10px; /* Add some spacing between the thumbnails */
  margin-top: 20px; /* Add space between the carousel and the thumbnails */
  position: relative; /* Ensure the thumbnails stay below the carousel */
  z-index: 1; /* Ensure the thumbnails are visible and not hidden behind other elements */
}

/* Style for thumbnail */
.thumbnail-img {
  flex: 1 0 21%; /* Allow each image to take up approximately 21% of the row (25% minus some margin for spacing) */
  max-width: 100px; /* Set the desired maximum width */
  height: 100px; /* Set the desired height */
  object-fit: cover; /* Ensure the image covers the entire space */
  margin-bottom: 10px; /* Space between rows */
  cursor: pointer; /* Add a pointer cursor for clickable images */
  margin-right: 5px;
  margin-left: 5px;
}

/* Style for selected thumbnail */
.selected-thumbnail {
  border: 2px solid rgb(18, 1, 92); /* Add your desired border style */
}

/* Ensure the carousel images stay within the container */
.carousel img {
  max-width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
}

/* Custom modal carousel images */
.custom-modal .carousel img {
  max-width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
}

/* Close button styles */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
  z-index: 1000; /* Ensure the button stays above everything */
}

/* Custom styles for carousel arrows */
.custom-carousel-arrow img {
  width: 60px;
  height: 60px;
}

.image-cont img:hover {
  cursor: pointer;
}
