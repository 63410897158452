.card {
  max-width: 350px;
  width: auto;
  height: 550px;
  display: flex;
  flex-direction: column;
}

.card-body {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.hovered .card-img {
  opacity: 0;
}

.hovered .card-img:hover {
  opacity: 1;
}

.card-name {
  margin-top: 10px;
}

.card-desc {
  margin-top: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-price-stock-row {
  margin-top: auto; /* Push the price and stock row to the bottom */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.in-stock {
  color: rgb(59, 194, 204);
}

.out-of-stock {
  color: gray;
}

.cart-button:disabled {
  background-color: gray !important;
  border-color: gray !important;
  cursor: not-allowed;
}

/* Apply grayscale effect only if the item is currently unavailable */
.out-of-stock-card {
  filter: grayscale(100%); /* Apply grayscale effect */
  opacity: 0.7; /* Reduce opacity */
  transition: filter 0.3s ease, opacity 0.3s ease;
}

/* Remove grayscale effect on hover */
.out-of-stock-card:hover {
  filter: grayscale(0%);
  opacity: 1;
}

/* Style for the image inside out-of-stock card */
.out-of-stock-card .card-img {
  transition: filter 0.3s ease; /* Ensure smooth transition for image */
}

/* Ensure the button is disabled when item is currently unavailable */
.out-of-stock-card .cart-button {
  background-color: gray !important;
  border-color: gray !important;
  cursor: not-allowed;
}

.out-of-stock-card .cart-button:hover {
  background-color: gray !important;
}

.cart-button {
  margin-top: auto; /* Push the button to the bottom */
  justify-content: end;
  border-radius: 0;
  background-color: #00446a;
}

.cart-button:hover {
  background-color: #00446a;
}

.card-price-stock-row .in-stock {
  color: green;
}

.card-price-stock-row .available-after-ordering {
  color: green;
}

.card-price-stock-row .available-3-to-5 {
  color: #ffcc00;
}

.card-price-stock-row .available-7-to-10 {
  color: #ff9900;
}

.card-price-stock-row .out-of-stock {
  color: red;
}

.card-price-stock-row .unknown {
  color: gray;
}
